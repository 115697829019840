<template>
  <span v-if="readonly" v-text="sRate" />
  <form-field-text
    v-else
    v-model="fRate"
    :class="sColor"
    :disabled="disabled"
    input-type="number"
    label="currency"
  >
    <template #append>
      <v-fade-transition leave-absolute>
        <v-progress-circular
          v-if="loading"
          color="info"
          indeterminate
          size="18"
          width="2"
        ></v-progress-circular>
      </v-fade-transition>
    </template>
  </form-field-text>
</template>
<script lang="ts">
import { EventBus } from "@/services/event-bus";
import { currencyFormat, DebounceFunction } from "@/plugins/helpers";
import {
  CurrencyRate,
  CurrencyRateData,
  CurrencyRates,
} from "@planetadeleste/vue-mc-gw";
import { CurrencyData } from "@planetadeleste/vue-mc-shopaholic";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { ConfigModule } from "@/store/config";
import { AppModule } from "@/store/app";

@Component
export default class CompanyCurrencyRateField extends Vue {
  @Prop(Object) readonly value!: CurrencyData;
  @Prop(Number) readonly companyId!: number;
  @Prop(Object) readonly currencies!: CurrencyRates;
  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly readonly!: boolean;
  @Prop({ type: Boolean, default: true }) readonly autoSave!: boolean;
  @Prop([Number, String]) readonly rateGlobal!: number;

  fnDebounceRate!: DebounceFunction;
  obCurrencyRate: CurrencyRate | null = null;
  fOldRate = 0;

  get loading() {
    return this.obCurrencyRate ? this.obCurrencyRate.loading : false;
  }

  get fRate() {
    return this.obCurrencyRate ? this.obCurrencyRate.get("rate_value") : null;
  }

  set fRate(fValue: number) {
    if (!this.obCurrencyRate) {
      return;
    }

    if (!this.fOldRate && fValue) {
      this.fOldRate = fValue;
    }

    this.obCurrencyRate.set("rate_value", Number(fValue));
  }

  get sRate() {
    return currencyFormat(this.fRate, this.currency?.code);
  }

  get fRateGlobal() {
    return this.rateGlobal || this.value.rate;
  }

  get sColor() {
    if (!this.fRate || !this.fRateGlobal) {
      return undefined;
    }

    return `${this.fRate > this.fRateGlobal ? "green" : "orange"}--text`;
  }

  get currency() {
    return this.obCurrencyRate?.get("currency") as CurrencyData | undefined;
  }

  @Watch("fRate")
  onChangeRate() {
    this.fnDebounceRate();
  }

  created() {
    this.fnDebounceRate = this.$_.debounce(this.save, 500);
    EventBus.on("get.company.currencies", this.setCurrencyData);
  }

  beforeDestroy() {
    EventBus.off("get.company.currencies", this.setCurrencyData);
  }

  mounted() {
    const obCurrencyData: CurrencyRateData | Record<string, any> = {
      company_id: this.companyId,
    };

    if (this.value.id) {
      obCurrencyData.currency_id = this.value.id;
    }

    this.obCurrencyRate = new CurrencyRate(obCurrencyData);

    if (this.currencies && this.currencies.length) {
      this.setCurrencyData(this.currencies);
    }
  }

  setCurrencyData(obCurrencies?: CurrencyRates) {
    if (
      !obCurrencies ||
      !this.value ||
      !this.companyId ||
      !this.obCurrencyRate
    ) {
      return;
    }

    if (obCurrencies.length) {
      const obCompanyCurrency = obCurrencies.find({
        company_id: this.companyId,
        currency_id: this.value.id,
      });

      if (obCompanyCurrency) {
        // this.obCurrencyRate.id = obCompanyCurrency.id;
        const fRate = Number(obCompanyCurrency.rate);
        this.obCurrencyRate.rate = obCompanyCurrency.rate;
        this.obCurrencyRate.set("rate_value", fRate);

        if (!this.fOldRate && fRate) {
          this.fOldRate = fRate;
        }
      }
    }
  }

  async save() {
    if (
      !this.obCurrencyRate ||
      this.disabled ||
      !this.fRate ||
      this.fOldRate === this.fRate
    ) {
      return;
    }

    this.obCurrencyRate.set("rate", this.fRate);
    this.obCurrencyRate.sync();
    this.$emit("update:item", this.obCurrencyRate);

    if (!this.autoSave) {
      return;
    }

    await this.obCurrencyRate.store();

    if (this.companyId === AppModule.company.id) {
      await ConfigModule.loadCompanyCurrencyRates(true);
    }
  }
}
</script>
